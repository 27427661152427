var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-main",
    [
      _c(
        "page-title",
        [
          _c(
            "template",
            { slot: "btn-inner" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.$router.push({
                        name: "news",
                        query: { activeName: _vm.$route.query.tableActiveName }
                      })
                    }
                  }
                },
                [
                  _c("i", { staticClass: "iconfont icon-fanhui" }),
                  _vm._v("返回 ")
                ]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading.detail,
              expression: "loading.detail"
            }
          ],
          staticClass: "partition-area"
        },
        [
          _c(
            "el-form",
            {
              ref: "appForm",
              attrs: {
                model: _vm.appForm,
                rules: _vm.rules,
                "label-width": "110px",
                "label-suffix": _vm.constants.labelSuffix
              }
            },
            [
              _c(
                "ics-page-inner",
                { attrs: { title: "消息内容" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 16 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "消息标题" } },
                        [
                          _c(
                            "ics-item-inner",
                            {
                              attrs: {
                                prop: _vm.appForm.informationTitle,
                                format: _vm.utils.isEffectiveCommon
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入消息标题" },
                                model: {
                                  value: _vm.appForm.informationTitle,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.appForm,
                                      "informationTitle",
                                      $$v
                                    )
                                  },
                                  expression: "appForm.informationTitle"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "div",
                        { staticClass: "editor-content" },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "informationContent",
                                label: "消息内容"
                              }
                            },
                            [
                              _c("div", { staticClass: "editor-container" }, [
                                _c("textarea", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.appForm.informationContent,
                                      expression: "appForm.informationContent"
                                    }
                                  ],
                                  attrs: { id: "editor" },
                                  domProps: {
                                    value: _vm.appForm.informationContent
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.appForm,
                                        "informationContent",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.viewEdit()
                        ? _c("el-form-item", { attrs: { label: "" } }, [
                            _c("p", { staticStyle: { color: "red" } }, [
                              _vm._v(" *注：消息内容的参数不允许修改 ")
                            ])
                          ])
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.viewEdit()
                    ? _c("ics-button-inner", {
                        attrs: {
                          loading: _vm.loading.submit,
                          "submit-title": "提交",
                          "cancel-title": "返回",
                          "show-cancel": false
                        },
                        on: { submit: _vm.submitForms }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }