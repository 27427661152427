<template>
  <el-main>
    <page-title>
      <template slot="btn-inner">
        <el-button @click="$router.push({ name: 'news', query: { activeName: $route.query.tableActiveName } })">
          <i class="iconfont icon-fanhui" />返回
        </el-button>
      </template>
    </page-title>
    <div v-loading="loading.detail" class="partition-area">
      <el-form ref="appForm" :model="appForm" :rules="rules" label-width="110px" :label-suffix="constants.labelSuffix">
        <ics-page-inner title="消息内容">
          <el-col :span="16">
            <el-form-item label="消息标题">
              <ics-item-inner :prop="appForm.informationTitle" :format="utils.isEffectiveCommon">
                <el-input v-model="appForm.informationTitle" placeholder="请输入消息标题" />
              </ics-item-inner>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div class="editor-content">
              <el-form-item prop="informationContent" label="消息内容">
                <div class="editor-container">
                  <textarea id="editor" v-model="appForm.informationContent" />
                </div>
              </el-form-item>
            </div>
            <el-form-item v-if="viewEdit()" label="">
              <p style="color: red;">
                *注：消息内容的参数不允许修改
              </p>
            </el-form-item>
          </el-col>
          <ics-button-inner v-if="viewEdit()" :loading="loading.submit" submit-title="提交" cancel-title="返回" :show-cancel="false" @submit="submitForms" />
        </ics-page-inner>
      </el-form>
    </div>
  </el-main>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
export default {
  components: {},
  mixins: [routeEnterMixin],
  data () {
    return {
      informationCode: this.$route.query.informationCode,
      appForm: {
        informationTitle: '',
        informationContent: ''
      },
      // 富文本编辑器默认内容
      content: '<h2>请输入内容..</h2>',
      // 富文本编辑器配置
      editorOption: {
        // Some Quill options...
      },
      rules: {}
    }
  },
  created () {
    if (this.informationCode) {
      this.getDetail()
    }
    this.$nextTick(() => {
      this.editorCkeditor()
    })
  },
  methods: {
    editorCkeditor () {
      this.editor = CKEDITOR.replace('editor', {
        removeButtons: 'Maximize,Source,Templates,About,CreateDiv,Language,BidiRtl,BidiLtr,Flash,Smiley,SpecialChar,Iframe,PageBreak,HiddenField,ImageButton,Button,Textarea,TextField,Select,Radio,Checkbox,SelectAll,Save,NewPage,ExportPdf,Preview,Print,Blockquote,ShowBlocks',
        image_previewText: ' ',
        isReadOnly: true,
        filebrowserImageUploadUrl: this.filebrowserImageUploadUrl
      })
    },
    getDetail () {
      this.loading.detail = true
      this.api.system.information.getInitDetail(this.informationCode).then(result => {
        const data = result.data.data
        this.appForm = data
        this.editor.setData(this.appForm.informationContent)
        if (!this.viewEdit()) {
          this.editor.setReadOnly(true)
        }
      }).finally(() => {
        this.loading.detail = false
      })
    },
    submitForms () {
      const contractContent = this.editor.getData()
      this.appForm.informationContent = contractContent
      this.$refs.appForm.validate().then(() => {
        const data = this._.cloneDeep(this.appForm)
        this.loading.submit = true
        this.api.system.information.initUpdate(data).then(result => {
          if (result.data.success === true) {
            this.loading.submit = false
            this.$message.success('操作成功')
            this.$router.push({ name: 'news', query: { activeName: this.$route.query.tableActiveName } })
          } else {
            this.$message.error(result.data.message)
          }
        }).catch(e => {
          this.loading.submit = false
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
